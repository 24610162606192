@import "minima";

.fade {
	position: relative;
	margin-bottom: 10px;
}

.fade img {
	position: absolute;
	left: 0;
	width: 100%;
	max-width: 100%;
	-webkit-transition: opacity 2s ease-in-out;
	-moz-transition: opacity 2s ease-in-out;
	-o-transition: opacity 2s ease-in-out;
	transition: opacity 2s ease-in-out;
}

.fade img.top {
	opacity: 0;
}

.fade img.top:hover {
	opacity: 1;
}

.fade img.bottom {
	position: relative
}

/*! I'm in here */